<template>

  <div class="row">
    <b-modal :id="formId" :size="formWidth">
      <template #modal-title>
        <div v-if="formState == 'Update'">Update Surveillances #{{ formData.id }}</div>
        <div v-if="formState == 'Create'">Create Surveillances</div>
      </template>

      <EditSurveillances v-if="formState == 'Update'" :key="formKey" :data="formData" :gridApi="formGridApi"
                         :modalFormId="formId" :usersData="usersData" @close="closeForm"/>


      <CreateSurveillances v-if="formState == 'Create'" :key="formKey" :gridApi="formGridApi"
                           :modalFormId="formId"
                           :usersData="usersData" @close="closeForm"/>

      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>

    <div class="col-sm-12">
      <AgGridTable :key="tableKey" :cacheBlockSize="cacheBlockSize" :columnDefs="columnDefs"
                   :extrasData="extrasData"
                   :maxBlocksInCache="maxBlocksInCache" :pagination="pagination"
                   :paginationPageSize="paginationPageSize"
                   :rowData="rowData" :rowModelType="rowModelType" :url="url" className="ag-theme-alpine"
                   domLayout='autoHeight' rowSelection="multiple" @gridReady="onGridReady">

        <template #header_buttons>
        </template>

      </AgGridTable>

    </div>
  </div>
</template>


<script>
import {mapGetters} from 'vuex';
import moment from 'moment'


export default {
  name: 'SurveillancesView',

  components: {
    AgGridTable: () => import("@/components/AgGridTable.vue"),
    CustomFiltre: () => import("@/components/CustomFiltre.vue"),
    CreateSurveillances: () => import( "./CreateSurveillances.vue"),
    EditSurveillances: () => import( "./EditSurveillances.vue"),
    DataModal: () => import("@/components/DataModal.vue"),
    AgGridBtnClicked: () => import("@/components/AgGridBtnClicked.vue")
  },
  props: ['type', 'typeValue'],
  data() {

    return {
      formId: "surveillances",
      formState: "",
      formData: {},
      formWidth: 'lg',
      formGridApi: {},
      formKey: 0,
      tableKey: 0,
      url: 'http://127.0.0.1:8000/api/surveillances-Aggrid1',
      table: 'surveillances',
      usersData: [],
      requette: 2,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      rowModelType: null,
      pagination: true,
      paginationPageSize: 100,
      cacheBlockSize: 10,
      cleImport: null,
      maxBlocksInCache: 1,
    }
  },

  computed: {
    ...mapGetters('app', ['subDomain', 'domain']),
    $routeData: function () {
      let router = {meta: {}};
      try {
        if (typeof window.routeData != 'undefined') {
          router = window.routeData
        }
      } catch (e) {
      }

      return router;
    },
    taille: function () {
      let result = 'col-sm-12'
      if (this.filtre) {
        result = 'col-sm-9'
      }
      return result
    },
    extrasData: function () {
      let retour = {};
      let params = {};
      if (this.type) {
        params['entite'] = {
          values: ['imports-agents', 'imports-agents-one', 'imports-postes', 'Imports-effectifs'],
          filterType: "set"
        };
        params['entite_cle'] = {values: [this.typeValue], filterType: "set"};

      } else {

      }
      retour["baseFilter"] = params;

      return retour;
    },
  },
  watch: {
    '$route': {
      handler: function (after, before) {
        this.gridApi.setFilterModel(null)
        this.gridApi.refreshServerSide()
        this.tableKey++
      },
      deep: true
    },
  },
  created() {
    this.url = this.axios.defaults.baseURL + '/api/surveillances-Aggrid1',
        this.formId = this.table + "_" + Date.now()
    this.rowBuffer = 0;
    this.rowModelType = 'serverSide';
    this.cacheBlockSize = 50;
    this.maxBlocksInCache = 2;

  },
  beforeMount() {
    this.columnDefs =
        [
          {
            field: null,
            headerName: '',
            suppressCellSelection: true,
            minWidth: 80, maxWidth: 80,
            pinned: 'left',
            cellRendererSelector: params => {
              return {
                component: 'AgGridBtnClicked',
                params: {
                  clicked: field => {
                    this.showForm('Update', field, params.api)
                  },
                  render: `<div class="btn btn-primary  d-flex justify-content-center align-items-center" style="width:100%;height:100%;color:#fff;border-radius:5px;text-align:center;cursor:pointer">  <i class="fa-solid fa-pen-to-square "></i></div>`

                }
              };
            },

          },
          {
            field: "surveillances.id",
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true,},
            hide: true,
            headerName: '#Id',
          },
          {
            field: "surveillances.created_at",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'Date',
            valueFormatter: params => {
              let retour = params.value
              try {
                retour = moment(params.value).format('DD/MM/YYYY à HH:mm')
              } catch (e) {

              }
              return retour
            }
          },
          {
            field: "action",
            sortable: true,
            headerName: 'Action',
            filter: 'agTextColumnFilter',
            floatingFilter: true,
          },
          {
            field: "entite",
            sortable: true,
            headerName: 'elements',
            filter: 'agTextColumnFilter',
            floatingFilter: true,
          },
          {
            field: "entite_cle",
            sortable: true,
            headerName: 'element id',
            filter: 'agTextColumnFilter',
            floatingFilter: true,
          },


          {
            headerName: 'utilisateur',
            field: 'users.nom',
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            join: {
              table: 'users',
              champ1: 'surveillances.user_id',
              champ2: 'users.id',
              operateur: '=',
            }
          },


          {
            field: "ip",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'Adresse IP',
            hide: true,
            floatingFilter: true,
          },


          {
            field: "pays",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            hide: true,
            headerName: 'Pays',

            floatingFilter: true,
          },


          {
            field: "ville",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            hide: true,
            headerName: 'Ville',

            floatingFilter: true,
          },

          {
            field: "navigateur",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            hide: true,
            headerName: 'Navigateur',
            floatingFilter: true,
          },

          {
            field: "ancien",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            hide: true,
            floatingFilter: true,
          },

          {
            field: "nouveau",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            hide: true,
            floatingFilter: true,
          },


        ];


  },
  mounted() {
    if (this.requette > 0) {
      // this.$store.commit('setIsLoading', true)
    }
    // this.getusers();
    if (this.type === 'agents-one') {
      this.type = 'agents_one'
      this.cleImport = ('IMPORTATIONS-' + this.type + '-' + this.typeValue).toUpperCase();

    } else {
      this.cleImport = ('IMPORTATIONS-' + this.type + '-' + this.typeValue).toUpperCase();

    }
  },
  methods: {
    openCreate() {
      this.showForm('Create', {}, this.gridApi)
    },
    closeForm() {
      try {
        this.gridApi.refreshServerSide()
      } catch (e) {

      }
    },
    showForm(type, data, gridApi, width = 'xl') {
      this.formKey++
      this.formWidth = width
      this.formState = type
      this.formData = data
      this.formGridApi = gridApi
      this.$bvModal.show(this.formId)
    },
    onGridReady(params) {
      console.log('on demarre', params)
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.isLoading = false
    },
    getusers() {

      this.axios.get('/api/users/type/1').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // // this.$store.commit('setIsLoading', false)
        }
        this.usersData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },


  }
}
</script>
